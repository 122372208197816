import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import Vue from 'vue'
import VueSession from "vue-session";
Vue.use(VueSession);

// create an axios instance
const service = axios.create({
  // baseURL: process.env.BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 50000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    Vue.prototype.$session.start()
    const token = Vue.prototype.$session.get('token')

    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (typeof (res.header) !== 'undefined' && res.header.error !== 'N') {
      /* Message({
        // message: "Username หรือ password ไม่ถูกต้อง กรุณาตรวจสอบอีกครั้ง" || 'Error',
        message: res.BODY || 'Error',
        type: 'error',
        duration: 5 * 1000
      })*/

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          Vue.prototype.$session.start()
          const role = Vue.prototype.$session.get('role')
          Vue.prototype.$session.clear()
          Vue.prototype.$session.destroy()
          if (role === 0)
            location.replace('/user/login')
          else
            location.replace('/login')
        })
      }
      return Promise.reject(res.header.message)
    } else {
      return res
    }
  },
  error => {
    let err = error
    const e401 = /401?$/.test(error)
    const role = Vue.prototype.$session.get('role')
    if (e401) {
      err = 'Sorry, your session was expired or invalid'
      Vue.prototype.$session.start()
      Vue.prototype.$session.clear()
      Vue.prototype.$session.destroy()
      if (role === 0)
        location.replace('/user/login')
      else
        location.replace('/login')
    }
    Message({
      message: err,
      type: 'error',
      duration: 5 * 1000,
      onClose: () => {
        if (e401) {
          //Vue.prototype.$store.state.loading = true
          if (role === 0)
            location.replace('/user/login')
          else
            location.replace('/login')
        }
      }
    })
    return Promise.reject(error)
  }
)

export default service
